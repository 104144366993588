<template>
  <div id="AddUser">
    <v-breadcrumbs :items="breadItems" divider=">"></v-breadcrumbs>
    <v-col
      cols="12"
      offset-md="2"
      offset-sm="2"
      offset-xs="1"
      md="8"
      sm="8"
      xs="6"
    >
      <v-alert :value="alert" color="red lighten-2" icon="mdi-cancel">
        Email déjà utilisé
      </v-alert>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="firstName"
              label="Nom"
              maxlength="20"
              :rules="firstNameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="lastName"
              label="Prénom"
              maxlength="20"
              :rules="LastNameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="username"
              maxlength="20"
              label=" Nom d'utilisateur"
              :rules="UserNameRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-select
              v-model="gender"
              :items="items"
              label="Gender"
              :rules="GenderRules"
              required
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="email"
              label="Mail"
              :rules="emailRules"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              disabled
              v-model="provider"
              label="Provider"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Mot de passe"
              hint="At least 8 characters"
              counter
              @click:append="showPassword = !showPassword"
              :rules="PasswordRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              block
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              name="input-10-1"
              label="Confirmer le mot de passe"
              counter
              :rules="ValidPasswordRules"
              @click:append="showPassword = !showPassword"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col align="center">
            <v-btn
              color="#00bbc0"
              style="margin-bottom: 5%"
              class="rounded-xl mx-12"
              :disabled="!valid"
              @click="validate"
            >
              Register
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </div>
</template>
<script>
import db from "../../../firebaseInit";
import firebase from "firebase";
import firebaseConfig from "../../../firebaseConfig";
import FirebaseDB from "../../utils/firebaseDB";
import NodesName from "../../utils/nodesName";

export default {
  name: "register",
  data() {
    return {
      breadItems: [
        {
          text: "Dashboard",
          disabled: false,
          href: "/",
        },
        {
          text: "Liste des utilisateurs",
          disabled: false,
          href: "/MercaAdmin/Users",
        },

        {
          text: "Ajouter utilisateur",
          disabled: true,
          href: "/Users/AddUser",
        },
      ],
      provider: "@mercarue.com",

      showPassword: false,
      dialog: true,
      tab: 0,
      tabs: [
        { name: "Login", icon: "mdi-account" },
        { name: "Register", icon: "mdi-account-outline" },
      ],
      valid: false,
      alert: false,
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      gender: null,
      password: null,
      verify: null,
      items: ["Male", "Female"],
      mercaMail: null,
      emailRules: [
        (v) => !!v || "Email est requis. ",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Email ne doit pas contenir de caractères spéciaux.",
        (v) =>
          (v || "").indexOf(" ") < 0 || "Les espaces ne sont pas autorisés.",
        (v) =>
          !/(?=.*[A-Z])/.test(v) ||
          "Email utilisateur ne doit pas contenir au moins un caractère majuscule. ",
      ],
      firstNameRules: [
        (v) => !!v || "Nom est requis ",
        (v) =>
          (v && v.length >= 4) || "Nom doit contenir au moins 4 caractères. ",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Nom ne doit pas contenir de caractères spéciaux.",
        (v) =>
          (v || "").indexOf(" ") < 0 || "Les espaces ne sont pas autorisés.",
      ],
      LastNameRules: [
        (v) => !!v || "Prénom est requis ",
        (v) =>
          (v && v.length >= 4) ||
          "Prénom doit contenir au moins 4 caractères. ",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Prénom  ne doit pas contenir de caractères spéciaux.",
        (v) =>
          (v || "").indexOf(" ") < 0 || "Les espaces ne sont pas autorisés.",
      ],

      UserNameRules: [
        (v) => !!v || "Nom d'utilisateur est requis. ",
        (v) =>
          (v && v.length >= 4) ||
          "Nom utilisateur doit contenir au moins 4 caractères.",
        (v) =>
          !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Nom utilisateur ne doit pas contenir de caractères spéciaux. ",
        (v) =>
          (v || "").indexOf(" ") < 0 || "les espaces ne sont pas autorisés.",
      ],
      GenderRules: [(v) => !!v || "Le genre est requis."],
      PasswordRules: [
        (v) => !!v || "Le mot de passe est requis. ",
        (v) =>
          /(?=.*[A-Z])/.test(v) ||
          "Le mot de passe doit contenir au moins un caractère majuscule. ",
        (v) =>
          /(?=.*[a-z])/.test(v) ||
          "Le mot de passe doit contenir au moins un caractère minuscule. ",
        (v) =>
          /[0-9]+/.test(v) ||
          "Le mot de passe doit contenir au moins un chiffre ",
        (v) =>
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(v) ||
          "Le mot de passe doit contenir au moins un caractère spécial. ",

        (v) =>
          (v && v.length >= 6) ||
          "Le mot de passe doit contenir au moins 6 caractères.",
        (v) =>
          (v || "").indexOf(" ") < 0 || "les espaces ne sont pas autorisés.",
      ],
      ValidPasswordRules: [
        (v) => !!v || "Verification Password est requis ",
        (v) =>
          (!!v && v) === this.password ||
          "Les deux mots de passes doit être identiques.",
      ],
    };
  },
  methods: {
    async validate() {
      this.mercaMail = this.email + this.provider;

      if (this.$refs.form.validate()) {
        var MailCount = await FirebaseDB.getCount(
          db
            .collection(NodesName.USERS_DETAILS)
            .where("email", "==", this.mercaMail)
        );
        if (MailCount > 0) {
          this.alert = true;
          this.email = null;
        } else {
          this.register();
        }
      }
    },
    register: function (e) {
      this.createdBy = firebase.auth().currentUser;

      this.createdByMail = this.createdBy.email;

      var secondaryApp = firebase.apps.map((e) => e.name).includes("Secondary")
        ? firebase.apps.find((o) => o.name === "Secondary")
        : firebase.initializeApp(firebaseConfig, "Secondary");

      secondaryApp
        .auth()
        .createUserWithEmailAndPassword(this.mercaMail, this.password)

        .then((userCredential) => {
          var user = userCredential.user;
          db.collection(NodesName.USERS_DETAILS)
            .doc(user.uid)
            .set({
              firstName: this.firstName,
              lastName: this.lastName,
              email: this.mercaMail,
              gender: this.gender,
              deleted: false,
              deletedBy: null,
              deletedDate: null,
              addresses: null,
              createdBy: this.createdByMail,
              creationDate: new Date(),
              phoneCountryNameCode: null,
              phoneNumber: null,
              photoUrl: null,
              privateUserAccountId: null,
              professional: false,
              professionalUserAccountId: null,
              updatedBy: null,
              updatedDate: null,
              userId: user.uid,
              userName: this.username,
            })
            .then(() => {
              console.log("Document successfully written!");
            });
          secondaryApp.auth().signOut();
          this.$router.push({
            name: "UserDetail",
            params: {
              userId: user.uid,
              name: this.firstName,
              surname: this.lastName,
              email: this.email,
            },
          });
        });
    },
  },
};
</script>
